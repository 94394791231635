import { Subject } from 'rxjs';
const subject = new Subject();
import axios from 'axios';
import { ID_TOKEN_KEY, USER_CREDIT } from '../../../store/constant/actions.type';
import JwtServices from './jwt.services';
import checkAuthTokenValidity from './auth.service';
let apiId = ""

const ApiUrl_url = process.env.VUE_APP_ENTRY_URL
const CARDIT_url = process.env.VUE_APP_USER_CARDIT


export const creditService = {
    sendCredit: userCredit => subject.next(userCredit),
    clearCredit: () => subject.next(),
    getCredit: () => subject.asObservable()
};


export async function Setuser_credit() {
    JwtServices.authorization().then(async res => {
        if (res) {
            await userCarditServices.get().then((res) => {
                apiId = res && res.data['apiId'];
                if (apiId && apiId != "") {
                    userApi.get().then((res) => {
                        localStorage.setItem(USER_CREDIT, res.data['currentCredit'])
                        creditService.sendCredit(res.data['currentCredit'])
                    }).catch((err) => {
                        if (err.response && err.response.data) {
                            // console.log(err.response.data.message)
                        }
                    });
                }
            })

        } else {
            window.location = `${process.env.VUE_APP_URL}login`;
        }
    })
}
export const userCarditServices = {
    async get() {
        const headers = { Authorization: localStorage.getItem(ID_TOKEN_KEY) }
        // return axios.get(`${ApiUrl_url}/api_id`, { headers });
        try {
            const response = await axios.get(`${ApiUrl_url}/api_id`, { headers });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }
    }
}
// process.env.VUE_APP_URL
export const userApi = {
    async get() {
        const headers = { Authorization: localStorage.getItem(ID_TOKEN_KEY) }
        // return axios.get(`${CARDIT_url}/mine?email='${email}&apiId=${apiId}`, { headers });

        // let email = localStorage.getItem(USER_EMAIL);
        let apiId = localStorage.getItem("$s")
        // return axios.get(`${CARDIT_url}/mine?apiId=${apiId}`, { headers });

        try {
            const response = await axios.get(`${CARDIT_url}/mine?apiId=${apiId}`, { headers });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }
    }
}